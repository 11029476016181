import React, { useState, createContext } from "react";
import questions from './questions.json';
import './bootstrap/bootstrap.css';
import Result from "./components/Result";
import QuizHeader from "./components/QuizHeader";
import QuizForm from "./components/QuizForm";
export const AppContext = createContext();

export default function App() {

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	
	if (showScore === false) {
		// don't forget to return what you want to render!
		return (
			<div className="app">
			<AppContext.Provider value={{ currentQuestion, setCurrentQuestion, score, setScore, showScore, setShowScore}}>
				<QuizHeader/>
				<QuizForm questions={questions}/>
			</AppContext.Provider>
			</div>
		);
	} else {
		return (
		<Result score={score} questions={questions}/>
		);
	}
	
}
