import React from 'react'

const QuizHeader = () => {
  return (
        <div className="mx-auto z-depth-1-half App-header">
            <img src="./VoterGuide2.png" className="App-logo" alt="logo" />
            <h2 id="mainTitle">Voter Guide</h2>
            <h3 id="subHeader">You Decide</h3>
            <p id="tagline">Find the political party matched to your stance on key issues.</p>
        </div>
    )
}

export default QuizHeader