import React from 'react'
import QuizHeader from './QuizHeader';

const Result = ({score, questions}) => {
    return (

<div className="app">
            <QuizHeader/>
        {score>=7 ?

<div>
            <div className="container result">
            <h2>You should vote for a Republican candidate.</h2>
            <p className="mt-3"><b>SCORE:</b> {score} / {questions.length} in support of Republican ideas.</p>
            <p><b>SCORE:</b> {15 - score} / {questions.length} in support of Democrat ideas.</p>
            <img src="./elephant.png" className="mt-1 elephant" alt="elephant" />
           </div> 
</div>
        : 
        <div>

        		<div className="container result">
                    <h2>You should vote for a Democratic candidate.</h2>
                	<p className="mt-3"><b>SCORE:</b> {15 - score} / {questions.length} in support of Democrat ideas.</p>
                	<p>SCORE: {score} / {questions.length} in support of Republican ideas.</p>
                	<img src="./donkey.png" className="donkey" alt="donkey" />
                </div>
                </div>
        }

                <div className='buttonDiv'>
                        <button
                            type="button"
                            className="w-30 BackButton btn btn-success mb-2"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Restart
                        </button>
                        </div>
                        
                        </div>
                      
  )
}


export default Result