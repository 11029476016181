import React, { useState, useContext } from 'react'
import { CSSTransition } from 'react-transition-group';
import { AppContext } from '../App';

const QuizForm = ({questions}) => {
    const { currentQuestion, setCurrentQuestion, score, setScore, setShowScore} = useContext(AppContext);
	const [inProp, setInProp] = useState(false);

    const handleAnswerOptionClick = (isRepublican) => {

		if (isRepublican) {
			setScore(score + 1);
		}

		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
		}
	};


    //console.log("value of score in form is: " + score);

  return (
    <form>
    <CSSTransition in={inProp} timeout={400} onEntering={() => setInProp(false)} classNames="fade">
        <div className="form-check container">
            <div className="question-section">
                <div className="questionCount">
                    <span>Question {currentQuestion + 1}</span>/
                    {questions.length}
                </div>
                
                <h4 className="question">
                    {questions[currentQuestion].questionText}
                </h4>
            </div>
            <div className="custom-radio-button radio custom-control custom-radio">
                <div className="form-group">
                    {questions[
                        currentQuestion
                    ].answerOptions.map((answerOption, index) => (
                        <li className="answerOption" key={index}>
                            <input onClick={() => {
                                setTimeout(() => {
                                    setInProp(true);
                                    document.getElementById("customRadio" + index).checked = false;
                                    handleAnswerOptionClick(answerOption.isRepublican);
                                }, 400);
                            }}
                                type="radio"
                                name="radio-group"
                                className="radioCustomButton form-check-input custom-control-input"
                                id={"customRadio" + index}
                            />
                            <label htmlFor={"customRadio" + index}
                                className="form-check-label radioCustomLabel custom-control-label">
                                {answerOption.answerText}
                            </label>
                        </li>
                     ))}
                </div>
            </div>
        </div>
    </CSSTransition>

    <div className='buttonDiv'>
        <button
            type="button"
            className="w-30 BackButton btn btn-success mb-2"
            onClick={() => {
                setCurrentQuestion(0);
                setScore(0);
            }}
        >
            Restart
        </button>

        <button
                type="button"
                className="w-30 BackButton btn btn-success mb-2"
                id="backBtn"
                onClick={() => {
                    setCurrentQuestion(currentQuestion - 1);
                    setScore(score - 1);
                }}
            >
                Previous
            </button>
            </div>
      </form>

  )
}



export default QuizForm